<template>
  <svg
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    width="22"
    height="22"
    version="1.1"
    viewBox="0 0 128 128"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <polygon
        :class="{ accent: accent, dark: dark, hovered: hovered }"
        points="91,119 9,119 9,9 91,9 91,1 1,1 1,127 91,127  "
      />
      <rect
        :class="{ accent: accent, dark: dark, hovered: hovered }"
        height="8"
        width="8"
        x="40"
        y="60"
      />
      <polygon
        :class="{ accent: accent, dark: dark, hovered: hovered }"
        points="90.8,96.8 123.7,64 90.8,31.2 85.2,36.8 108.3,60 58,60 58,68 108.3,68 85.2,91.2  "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
  props: {
    accent: Boolean,
    dark: Boolean
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
svg {
  cursor: pointer;
}
.accent {
  fill: $accent;
}

.hovered {
  fill: $accent;
}

.dark {
  fill: white;
  &.hovered {
    fill: $accent;
  }
}
</style>
